import React from "react"
import "../components/timmi.css"
import { StaticQuery, graphql } from "gatsby"
import github from "../images/github.svg"
import lin from "../images/linkedin.svg"
import discord from "../images/discord.svg"

export default function Contact() {
  return (
    <section className="timmiPage" id="contact">
      <section className="contactPage">
        <address>
          <div className="contactitem">
            <a href="https://discord.gg/D2xvYRX">
              <img src={discord} alt="discord logo" />
            </a>
          </div>
          <div className="contactitem">
            <a href="https://github.com/timmify">
              <img src={github} alt="github logo" />
            </a>
          </div>
          <div className="contactitem">
            <a href="https://www.linkedin.com/in/tiemo-hunger/">
              <img src={lin} alt="linkedin logo" />
            </a>
          </div>
        </address>
      </section>
    </section>
  )
}
