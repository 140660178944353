import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "slug": "/changelog",
  "title": "changelog"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function Layout({
  children
}) {
  return <>{children}</>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="introduction" id="log">
      <h2>{`log`}</h2>
      <h2>{`Future`}</h2>
      <h2>{`Next Up`}</h2>
      <p>{` Introduce social media components`}</p>
      <p>{`Activate Book Club app and discord channel`}</p>
      <p>{`Implement Blog Page`}</p>
      <p>{`Add Current Project to the top of the Page`}</p>
      <p>{`Shorten introduction `}</p>
      <p>{`Move introduction to seperate page`}</p>
      <h2>{`Present`}</h2>
      <p>{`30th of September 2020, 23:23 pm CET`}</p>
      <hr></hr>
      <p>{`Releasing first parts of my portfolio.`}</p>
      <h2>{`Past`}</h2>
      <p>{`11th of August 2020, 23:23 pm CET`}</p>
      <hr></hr>
      <p>{`Releasing the first unperfected version of timmi.dev`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      