import React from 'react';
import '../components/timmi.css'
import { StaticQuery, graphql } from "gatsby"
import Introduction from '../marx/introduction.mdx';
export default function Intro(){
    return(
        <section className="timmiPage" id="introduction">
               {/* <h1 className="pageHeading"> */}
                   <Introduction></Introduction>
               {/* </h1> */}
        </section>
    )
}

