import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "slug": "/introduction",
  "title": "Cover"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function Layout({
  children
}) {
  return <>
      {children}
    </>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="introduction">
      <h2>{`Hi, I am Timmi`}</h2>
      <p>{`aka Tiemo Hunger. Welcome to my laboratory!`}</p>
      <p>{`I graduated in Computer Science and have been working in and for
small and big enterprises, from co-founding over self-employment.`}</p>
      <p>{`I specialized mainly in user facing technologies and web-technologies. Recognizing trends and beeing an
early adopter as well as a product inventor are the basics of my daily work.`}</p>
      <p>{`What else? Born and raised in Linz, Austria, living and creating in Switzerland since 2011.`}</p>
      <p>{`My next step? Moving to Klagenfurt, Austria - working on big products.`}</p>
      <p>{`My interests are versatile, from arts to zeta - functions.
I took arts education as well as I am currently studying math and economics as an undergraduate. `}</p>
      <p>{`I like creature centered experience design approaches (for everyone, not only human users), all this AI stuff which is going on right now,
playing around with quantum computer apis and hopping at the bleeding edge. `}</p>
      <p>{`Discord, email - me, etc.... See You Around!
Tiemo, Timmi.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      