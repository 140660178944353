import React from "react"
import { PageProps } from "gatsby"
import SEO from "../components/seo"
import { Timmi } from "../components/timmi"
import Portfolio from "./portfolio"
import Home from "./home"
import ArrowDown from "../components/arrowDown"
import Intro from "./intro"
// import Impressum from "../marx/impressum.mdx"
import Lab from "./lab"
import Contact from "./contact"
import ChangeLog from "./changelog"
import BookClub from "./bookclub"
import { Navigation } from "../components/navigation"

export default function Index(props: PageProps) {
  return (
    <>
      <SEO title="timmi" />
      <Navigation></Navigation>
      <Home></Home>
      <Intro></Intro>
      {/* <Portfolio></Portfolio> */}
      {/* <Lab></Lab> */}
      <Contact></Contact>
      {/* <BookClub></BookClub> */}
      <ChangeLog></ChangeLog>
      {/* <Impressum></Impressum> */}
    </>
  )
}
