import React from "react"
import "./timmi.css"

import { Link } from "react-scroll"

export function Navigation() {
  return (
    <center>
      <nav>
        <ul>
          <li>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              duration={750}
            >
              start
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="introduction"
              spy={true}
              smooth={true}
              duration={750}
            >
              Hi
            </Link>
          </li>
          {/* <li>
         
            <Link
              activeClass="active"
              to="portfolio"
              spy={true}
              smooth={true}
              duration={750}
            >
              portfolio
            </Link>
          </li> */}
          {/* <li>
         
            <Link
              activeClass="active"
              to="lab"
              spy={true}
              smooth={true}
              duration={750}
            >
              lab
            </Link>
          </li> */}
          {/* <li>
            <Link
              activeClass="active"
              to="bookclub"
              spy={true}
              smooth={true}
              duration={750}
            >
              bookclub
            </Link>
          </li> */}
          <li>
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              duration={750}
            >
              contact
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="log"
              spy={true}
              smooth={true}
              duration={750}
            >
              log
            </Link>
          </li>
        </ul>
      </nav>
    </center>
  )
}
