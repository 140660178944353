import React from 'react';
import '../components/timmi.css'
import { StaticQuery, graphql } from "gatsby"
import Changelog from '../marx/changeLog.mdx';
export default function ChangeLog(){
    return(
        <section className="timmiPage" id="log">
             <Changelog></Changelog>
        </section>
    )
}
